body {
    margin: 0;
    font-size: 16px;
    font-family: 'Avenir Book', 'Arial', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    max-width: 100%;
    color: #ffffff;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
    font-size: 3rem;
    font-family: "Avenir Heavy", sans-serif;
}

a {
    color: var(--link-color);
}

a:hover {
    color: var(--link-color);
    text-decoration: none;
    opacity: .6;
}

.wrapper {
    margin: 0 auto;
    overflow-x: hidden;
    width: 100%;
}

.how-exchange-works__wrapper {
    position: relative;
    background-color: var(--background-gre);
}

.header-block {
    background-color: var(--main-color);
}

.faq-block {
    background-color: var(--main-color);
    z-index: 1;
    position: relative;
    height: 100%;
    min-height: 500px;
}

.footer {
    background-color: var(--main-color);
}

@media (max-width: 994px) {
    h2 {
        font-size: 2rem;
    }
}
