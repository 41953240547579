.document-container {
    padding: 20px;
    border: 1px solid #ddd;
}

.document-content {
    display: none;
    margin-top: 20px;
}

.show {
    display: block;
}
