@font-face {
    font-family: 'Avenir Heavy';
    src: url("../fonts/Avenir-Heavy.ttf");
}

@font-face {
    font-family: "Avenir Book";
    src: url("../fonts/Avenir-Book.ttf");
}

@font-face {
    font-family: "Avenir Medium";
    src: url("../fonts/Avenir-Medium.ttf");
}
